import React, { forwardRef, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import DatePicker from 'react-datepicker';
import FormInput from './FormInput';
import SelectInput from './SelectInput';
import { adminAPI } from '../api';
import DollarIcon from '../../../../assets/images/reskin-images/icon--dollar.svg';
import Toggle from './Toggle';
import useAuth from '../context/AuthContext';
import Modal from '../components/Modal';
import useAlarm from '../context/AlarmContext';

const UserInviteForm = ({
  handleChange,
  courses,
  formData,
  hubspotAccessTypes,
  limitFields,
  includedFields,
  toggleIncludedField,
  application,
  isEditUser = false,
}) => {
  const { user_invite: userInvite } = formData;
  const { userData } = useAuth();
  const { setAlarm } = useAlarm();
  const roles = userData?.roles || [];
  const hasInvite = userInvite?.id;

  const [inviteFormExists, setInviteFormExists] = useState(
    isEditUser ? !!hasInvite : true
  );
  // COURSES SELECT LOGIC
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(
    selectedCourses.length === courses.length
  );
  const queryClient = useQueryClient();

  const mutation = useMutation(adminAPI.destroyInvites, {
    onSuccess: () => {
      queryClient.invalidateQueries('activeUsers');
      setInviteFormExists(false);
      setAlarm({ type: 'success', message: 'Invite deleted successfully' });
    },
    onError: (error) => {
      setAlarm({
        type: 'error',
        message: 'Failed to delete invite: ' + error.message,
      });
      setContent();
    },
  });

  const handleDeleteInvite = () => {
    if (hasInvite) {
      const serializedData = JSON.stringify([{ id: userInvite.id }]);
      mutation.mutate(serializedData);
    } else {
      setAlarm({ type: 'error', message: 'No invite to delete' });
    }
  };

  useEffect(() => {
    const initiallyEnrolledCourses = courses
      .filter((course) => course.enrolled_in)
      .map((course) => course?.id);
    setSelectedCourses(initiallyEnrolledCourses);
  }, [courses]);

  const handleCheckAll = (checked) => {
    if (checked) {
      setSelectedCourses(courses.map((course) => course?.id));
      setIsCheckAll(true);
    } else {
      setSelectedCourses([]);
      setIsCheckAll(false);
    }
  };

  const handleCourseCheck = (checked, courseId) => {
    if (checked) {
      setSelectedCourses((prevCourses) => [...prevCourses, courseId]);
    } else {
      setSelectedCourses((prevCourses) =>
        prevCourses.filter((id) => id !== courseId)
      );
    }
  };

  useEffect(() => {
    handleChange('course_ids', selectedCourses, 'user_invite');
    setIsCheckAll(selectedCourses.length === courses.length);
  }, [selectedCourses]);

  useEffect(() => {
    if (inviteFormExists) {
      handleChange('course_ids', selectedCourses, 'user_invite');
      handleChange(
        'invitation_access',
        userInvite?.invitation_access || 'limited',
        'user_invite'
      );
      handleChange('skip_email', false, 'user_invite');
    }
  }, [inviteFormExists, selectedCourses]);

  // DATE LOGIC
  const today = new Date();
  const oneWeekFromToday = new Date(today);
  oneWeekFromToday.setDate(today.getDate() + 7);
  const oneYearFromToday = new Date(today);
  oneYearFromToday.setFullYear(today.getFullYear() + 1);
  const [invitationAccess, setInvitationAccess] = useState(
    userInvite?.invitation_access || 'limited'
  );
  const [selectedDate, setSelectedDate] = useState(
    userInvite?.expires_at
      ? new Date(userInvite?.expires_at)
      : invitationAccess === 'limited'
      ? oneWeekFromToday
      : oneYearFromToday
  );
  const [skipEmail, setSkipEmail] = useState(!!userInvite?.skip_email);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!userInvite?.expiration_type) {
      if (!inviteFormExists || hasChanges) {
        setSelectedDate(oneWeekFromToday);
        handleChange('expires_at', oneWeekFromToday, 'user_invite');
      }
      handleChange('expiration_type', 'date', 'user_invite');
    } else {
      if (userInvite?.expiration_type === 'length') {
        if (!inviteFormExists || hasChanges) {
          setSelectedDate(oneWeekFromToday);
          handleChange('expires_at', oneWeekFromToday, 'user_invite');
        }
        handleChange('valid_for_days', 7, 'user_invite');
        setToggleLengthField(true);
      }
      if (userInvite?.expiration_type === 'date') {
        handleChange('valid_for_days', null, 'user_invite');
        setToggleLengthField(false);
      }
    }
  }, [userInvite?.expiration_type]);

  useEffect(() => {
    if (!inviteFormExists || hasChanges) {
      if (invitationAccess === 'limited') {
        setSelectedDate(oneWeekFromToday);
        handleChange('expires_at', oneWeekFromToday, 'user_invite');
      }
      if (invitationAccess === 'unlimited') {
        setSelectedDate(oneYearFromToday);
        handleChange('expires_at', oneYearFromToday, 'user_invite');
      }
    }
  }, [invitationAccess]);

  const handleInvitationAccessChange = (value) => {
    setInvitationAccess(value);
    handleChange('invitation_access', value, 'user_invite');
  };

  const showField = (fieldId) => {
    if (!limitFields) return true;
    return includedFields?.includes(fieldId);
  };

  const [validForDays, setValidForDays] = useState(userInvite?.valid_for_days);
  const [toggleLengthField, setToggleLengthField] = useState(true);
  const [lengthType, setLengthType] = useState(userInvite?.length_type);

  const [showRatePricing, setShowRatePricing] = useState(
    !!userInvite?.discount_cents > 0
  );

  const [accessType, setAccessType] = useState(
    hubspotAccessTypes.some(
      (option) => option.value === userInvite?.access_type
    )
      ? userInvite?.access_type
      : 'N/A'
  );

  useEffect(() => {
    if (userInvite?.expiration_type === 'length') {
      handleChange('valid_for_days', 7, 'user_invite');
      handleChange('expires_at', null, 'user_invite');
      setToggleLengthField(true);
    }
    if (userInvite?.expiration_type === 'date') {
      handleChange('valid_for_days', null, 'user_invite');
      setToggleLengthField(false);
    }
  }, [userInvite?.expiration_type]);

  useEffect(() => {
    setValidForDays(userInvite?.valid_for_days);
  }, [userInvite?.valid_for_days]);

  useEffect(() => {
    setLengthType(userInvite?.length_type);
  }, [userInvite?.length_type]);

  return inviteFormExists ? (
    <div style={{ gap: '3rem' }} className="flex flex-col mt-4">
      {application?.includes('ALD') && (
        <>
          {/* Modules */}
          <div>
            <div className="flex items-center">
              <p className="text-sm uppercase text-charcoal  font-bold">
                Modules
              </p>
              {limitFields && (
                <Toggle
                  triggerFunction={toggleIncludedField}
                  field="course_ids"
                  className="ml-4"
                />
              )}
            </div>
            {showField('course_ids') && (
              <div className="rounded-lg border-2 border-gray p-2 mt-4 relative overflow-hidden">
                <div className="p-4 flex items-center border-b-2 border-gray">
                  <input
                    id="select-all"
                    className="mr-4"
                    type="checkbox"
                    checked={isCheckAll}
                    onChange={(e) => handleCheckAll(e.target.checked)}
                  />
                  <label htmlFor="select-all">Select All</label>
                </div>
                {courses.map((course) => {
                  const { id, title } = course;
                  return (
                    <div className="px-4 py-2 flex items-center" key={id}>
                      <input
                        id={id}
                        className="mr-4"
                        type="checkbox"
                        checked={selectedCourses.includes(id)}
                        onChange={(e) =>
                          handleCourseCheck(e.target.checked, id)
                        }
                      />
                      <label htmlFor={id}>{title}</label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}

      {/* Access Type */}
      <div>
        <div className="flex items-center">
          <p className="text-sm uppercase text-charcoal  font-bold">
            Access Types
          </p>
          {limitFields && (
            <Toggle
              triggerFunction={toggleIncludedField}
              field="access_type"
              className="ml-4"
            />
          )}
        </div>
        {hubspotAccessTypes && showField('access_type') && (
          <SelectInput
            className="mt-4"
            name="access_type"
            value={accessType}
            options={hubspotAccessTypes}
            onChange={(name, value) => {
              setAccessType(value);
              handleChange(name, value, 'user_invite');
            }}
          />
        )}
      </div>
      {/* Add invitation Access */}
      <div className="">
        <div className="flex items-center">
          <p className="text-sm uppercase text-charcoal  font-bold">
            {userInvite?.id
              ? 'Update Invitation Access'
              : 'Add Invitation Access'}
          </p>
          {limitFields && (
            <Toggle
              triggerFunction={toggleIncludedField}
              field="invitation_access"
              className="ml-4"
            />
          )}
        </div>

        {showField('invitation_access') && (
          <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
            <button
              onClick={() => {
                handleInvitationAccessChange('limited');
                setHasChanges(true);
              }}
              type="button"
              className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                invitationAccess === 'limited' && 'bg-purple-200'
              }`}
            >
              <p className="text-sm  font-semibold">Limited</p>
              <p className="text-sm  mt-2">Defaults to one week.</p>
              <p className="text-sm ">Sends "Week Access" email</p>
            </button>
            <button
              onClick={() => {
                handleInvitationAccessChange('unlimited');
                setHasChanges(true);
              }}
              type="button"
              className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                invitationAccess === 'unlimited' && 'bg-purple-200'
              }`}
            >
              <p className="text-sm  font-semibold">Unlimited</p>
              <p className="text-sm  mt-2">Defaults to one year.</p>
              <p className="text-sm ">Sends "Welcome" email</p>
            </button>
          </div>
        )}
      </div>
      {/* Add expiration type */}
      <div className="">
        <div className="flex items-center">
          <p className="text-sm uppercase text-charcoal  font-bold">
            {userInvite?.id ? 'Update Expiration Type' : 'Add Expiration Type'}
          </p>
          {limitFields && (
            <Toggle
              triggerFunction={toggleIncludedField}
              field="expiration_type"
              className="ml-4"
            />
          )}
        </div>
        {showField('expiration_type') && (
          <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
            <button
              onClick={() => {
                handleChange('expiration_type', 'length', 'user_invite');
              }}
              type="button"
              className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                userInvite?.expiration_type === 'length' && 'bg-purple-200'
              }`}
            >
              <p className="text-sm  font-semibold">Fixed Length</p>
              <p className="text-sm  mt-2">
                User has access for a fixed period
              </p>
              <p className="text-sm  mt-2">after joining.</p>
            </button>
            <button
              onClick={() => {
                handleChange('expires_at', selectedDate, 'user_invite');
                handleChange('expiration_type', 'date', 'user_invite');
              }}
              type="button"
              className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                userInvite?.expiration_type === 'date' && 'bg-purple-200'
              }`}
            >
              <p className="text-sm  font-semibold">Fixed Date </p>
              <p className="text-sm  mt-2">
                User has access until selected date.
              </p>
            </button>
          </div>
        )}
      </div>
      {/* Invitation Length */}
      {toggleLengthField && (
        <div className="">
          <div className="flex items-center">
            <p className="text-sm uppercase text-charcoal  font-bold">
              Invitation Length
            </p>
            {limitFields && (
              <Toggle
                triggerFunction={toggleIncludedField}
                field="valid_for_days"
                className="ml-4"
              />
            )}
          </div>
          {showField('valid_for_days') && (
            <FormInput
              handleChange={(e) => {
                handleChange('valid_for_days', e.target.value, 'user_invite');
              }}
              type="number"
              value={userInvite?.valid_for_days}
              style={{ maxWidth: '55px' }}
            />
          )}

          {/* Length Type */}
          {showField('valid_for_days') && showField('length_type') && (
            <div className="flex rounded-lg overflow-hidden border-2 border-gray mt-4">
              <button
                onClick={() => {
                  handleChange('length_type', 'days', 'user_invite');
                }}
                type="button"
                className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                  userInvite?.length_type === 'days' && 'bg-purple-200'
                }`}
              >
                <p className="text-sm  font-semibold">Days</p>
              </button>
              <button
                onClick={() => {
                  handleChange('length_type', 'years', 'user_invite');
                }}
                type="button"
                className={`flex flex-col items-center justify-center py-4 w-1/2 ${
                  userInvite?.length_type === 'years' && 'bg-purple-200'
                }`}
              >
                <p className="text-sm  font-semibold">Years</p>
              </button>
            </div>
          )}
        </div>
      )}
      {!toggleLengthField && (
        <div className="">
          <div className="flex items-center">
            <p className="text-sm uppercase text-charcoal  font-bold">
              Expiration Date
            </p>
            {limitFields && (
              <Toggle
                triggerFunction={toggleIncludedField}
                field="expires_at"
                className="ml-4"
              />
            )}
          </div>
          {selectedDate && showField('expires_at') && (
            <FormInput
              value={
                userInvite.expires_at
                  ? new Date(userInvite.expires_at).toISOString().split('T')[0]
                  : selectedDate.toISOString().split('T')[0]
              }
              type="date"
              handleChange={(e) => {
                setSelectedDate(e.target.value);
                handleChange('expires_at', e.target.value, 'user_invite');
              }}
            />
          )}
        </div>
      )}
      {/* Misc */}
      <div className="">
        <p className="text-sm uppercase text-charcoal  font-bold mb-4">Misc.</p>
        <div className="rounded-lg border-2 border-gray p-2 mt-4">
          {application?.includes('ALD') && (
            <div className="flex items-center">
              <input
                className="mx-4"
                type="checkbox"
                checked={userInvite?.unlimited_gifts ?? false}
                onChange={(e) =>
                  handleChange(
                    'unlimited_gifts',
                    e.target.checked,
                    'user_invite'
                  )
                }
                disabled={!showField('unlimited_gifts')}
              />
              <p className={!showField('unlimited_gifts') && 'gray-text'}>
                Allow unlimiting gifting
              </p>
              {limitFields && (
                <Toggle
                  triggerFunction={toggleIncludedField}
                  field="unlimited_gifts"
                  className="ml-4"
                />
              )}
            </div>
          )}
          <div className="flex">
            <input
              className="mx-4"
              type="checkbox"
              checked={skipEmail}
              onChange={(e) => {
                setSkipEmail(e.target.checked);
                handleChange('skip_email', e.target.checked, 'user_invite');
              }}
            />
            <p>Skip sending email</p>
          </div>
          {application?.includes('ALD') && (
            <>
              <div className="flex">
                <input
                  className="mx-4"
                  type="checkbox"
                  checked={userInvite?.opt_out_eop ?? false}
                  onChange={(e) =>
                    handleChange('opt_out_eop', e.target.checked, 'user_invite')
                  }
                />
                <p>Opt out of EOP</p>
              </div>
              {showField('discount_cents') && (
                <div className="flex">
                  <input
                    className="mx-4"
                    type="checkbox"
                    checked={showRatePricing}
                    onChange={() => {
                      if (!showRatePricing === false) {
                        handleChange('discount_cents', 0, 'user_invite');
                      }
                      setShowRatePricing(!showRatePricing);
                    }}
                  />
                  <p>Set preferred rate pricing</p>
                </div>
              )}
              {showRatePricing && (
                <div className="flex items-center">
                  <FormInput
                    handleChange={(e) =>
                      handleChange(
                        'discount_cents',
                        e.target.value,
                        'user_invite'
                      )
                    }
                    type="number"
                    value={userInvite.discount_cents}
                    icon={DollarIcon}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {(roles?.includes('admin') || roles?.includes('manager')) &&
        isEditUser && (
          <button
            type="button"
            className="bg-purple-100 hover:bg-purple-200 p-4 flex items-center justify-center rounded-lg mt-2 font-semibold "
            onClick={handleDeleteInvite}
          >
            Delete Invite
          </button>
        )}
    </div>
  ) : (
    <div className="flex items-center flex-col w-full">
      <p>User does not have an invite</p>
      <button
        type="button"
        className="bg-purple-100 hover:bg-purple-200 p-4 flex items-center justify-center rounded-lg mt-2 font-semibold "
        onClick={() => setInviteFormExists(true)}
      >
        Create Invite
      </button>
    </div>
  );
};

export default UserInviteForm;
