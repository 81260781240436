import React, { useState } from 'react';
import OutsideDetector from '../hooks/outsideDetector';
import DropdownMenu from './DropdownMenu';
import FormLoader from './FormLoader';

const AdminExportUsers = ({
  baseUrl,
  method = 'GET',
  body,
  filterParams,
  dropdownItems,
  customActionDownload = null,
}) => {
  const [showLinkDropdown, setShowLinkDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const download = (url, params = null) => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const name = `${month}-${day}-${year}`;
    setShowLinkDropdown(!showLinkDropdown);
    setIsLoading(!isLoading);
    const queryString = params
      ? '?' + new URLSearchParams(params).toString()
      : '';

    fetch(baseUrl + url + queryString, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv',
      },
      body: body ? JSON.stringify(body) : undefined,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const urlLink = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', `${name}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const list = dropdownItems.map((item) => {
    return {
      ...item,
      path: item.path || '',
      role: item.role || 'admin',
      subList: item.subList || [],
      onClick: customActionDownload
        ? customActionDownload
        : () => {
            download(item.url, item?.params || filterParams);
          },
    };
  });

  return (
    <OutsideDetector
      className="relative"
      stateSetter={setShowLinkDropdown}
      style={{ marginLeft: '0.5rem' }}
    >
      <button
        type="button"
        className="btn--inverse border flex p-4  font-semibold rounded-lg"
        onClick={() => setShowLinkDropdown(!showLinkDropdown)}
      >
        <span>Export</span>
        <i className="mx-1 far fa-angle-down" />
      </button>
      {showLinkDropdown && <DropdownMenu className="mt-3 pin-r" list={list} />}
      {isLoading && <FormLoader />}
    </OutsideDetector>
  );
};

export default AdminExportUsers;
