import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  className,
  href,
  onClick,
  children,
  variant,
  to,
  style,
  disabled = false,
  icon = null,
  iconPosition = 'left',
}) => {
  const El = href ? 'a' : 'button';

  const [buttonClass, setButtonClass] = useState();

  const Icon = ({ icon, position = 'left', isSvg = false }) => {
    if (!icon) return null;

    const marginClass = position === 'left' ? 'mr-2' : 'ml-2';

    return (
      <img
        src={icon}
        alt={`icon-${position}`}
        className={`${marginClass} h-5 w-5`}
      />
    );
  };

  const buttonClassFunc = useCallback(() => {
    switch (variant) {
      case 'purpleText':
        return 'text-link-purple text-sm font-black';
      case 'purpleTextNormal':
        return 'text-link-purple';
      case 'outline':
        return `px-6 py-3 bg-white font-extrabold flex rounded-full border-2 ${
          !disabled
            ? 'text-link-purple border-link-purple hover:text-white hover:bg-link-purple'
            : 'text-link-gray border-link-gray'
        }`;
      case 'outline-transparent':
        return `px-6 py-3 bg-transparent font-extrabold flex rounded-full border-2 ${
          !disabled
            ? 'text-link-purple border-link-purple hover:text-white hover:bg-link-purple'
            : 'text-link-gray border-link-gray'
        }`;

      default:
        return `flex px-6 py-3 border-2 text-white font-extrabold uppercase rounded-full ${
          !disabled
            ? 'bg-link-purple border-link-purple hover:bg-purple-500 hover:border-purple-500'
            : 'bg-gray-dark border-gray-dark '
        }`;
    }
  }, [variant, disabled]);

  useEffect(() => {
    const res = buttonClassFunc();
    setButtonClass(res);
  }, [disabled]);

  return to ? (
    <Link
      style={{ width: 'fit-content', ...style }}
      className={`uppercase no-underline ${buttonClass} ${className}`}
      to={to}
      disabled={disabled}
    >
      {icon && iconPosition === 'left' && (
        <Icon icon={icon} position={iconPosition} />
      )}
      <div>{children}</div>
      {icon && iconPosition === 'right' && (
        <Icon icon={icon} position={iconPosition} />
      )}
    </Link>
  ) : (
    <El
      style={{ width: 'fit-content', ...style }}
      href={href}
      onClick={onClick}
      className={`uppercase no-underline ${buttonClass} ${className}`}
      disabled={disabled}
    >
      {icon && iconPosition === 'left' && (
        <Icon icon={icon} position={iconPosition} />
      )}
      <div>{children}</div>
      {icon && iconPosition === 'right' && (
        <Icon icon={icon} position={iconPosition} />
      )}
    </El>
  );
};

export default Button;
